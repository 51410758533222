// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-buenas-practicas-js": () => import("./../../../src/templates/buenas-practicas.js" /* webpackChunkName: "component---src-templates-buenas-practicas-js" */),
  "component---src-templates-condiciones-js": () => import("./../../../src/templates/condiciones.js" /* webpackChunkName: "component---src-templates-condiciones-js" */),
  "component---src-templates-facturacion-js": () => import("./../../../src/templates/facturacion.js" /* webpackChunkName: "component---src-templates-facturacion-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-mediosdepago-js": () => import("./../../../src/templates/mediosdepago.js" /* webpackChunkName: "component---src-templates-mediosdepago-js" */),
  "component---src-templates-preguntas-frecuentes-js": () => import("./../../../src/templates/preguntas-frecuentes.js" /* webpackChunkName: "component---src-templates-preguntas-frecuentes-js" */),
  "component---src-templates-promociones-js": () => import("./../../../src/templates/promociones.js" /* webpackChunkName: "component---src-templates-promociones-js" */),
  "component---src-templates-reclamos-js": () => import("./../../../src/templates/reclamos.js" /* webpackChunkName: "component---src-templates-reclamos-js" */),
  "component---src-templates-solicitud-js": () => import("./../../../src/templates/solicitud.js" /* webpackChunkName: "component---src-templates-solicitud-js" */)
}

